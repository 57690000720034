export default class SeatPriceCalculator {

    static DISCOUNT_RANGES = [
        { range: [0, 99], value: 25.00 },
        { range: [100, 499], value: 23.75 },
        { range: [500, 999], value: 23.12 },
        { range: [1000, 1999], value: 22.50 },
        { range: [2000, 3999], value: 21.25 },
        { range: [4000, 7499], value: 20.00 },
        { range: [7500, Infinity], value: 18.75 },
    ];

    constructor(number_of_seats = 0) {
        if (number_of_seats < 0) {
            throw new Error('Invalid Number of Seats');
        }

        this.number_of_seats = number_of_seats;
    }

     calculateDiscountPercentage(bonusValue) {
        if (bonusValue < 100) {
            return 0;
        } else if (bonusValue < 500) {
            return 5;
        } else if (bonusValue < 1000) {
            return 10;
        } else if (bonusValue < 2000) {
            return 15;
        } else if (bonusValue < 4000) {
            return 20;
        } else if (bonusValue <= 7500) {
            return 25;
        } else {
            throw new Error('Invalid Bonus Value');
        }
    }

    static formatCurrency(value) {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    useNIHSSOrganizationsBonus() {
        const bonusValue = SeatPriceCalculator.DISCOUNT_RANGES.find(
            range => this.number_of_seats >= range.range[0] && this.number_of_seats <= range.range[1]
        );
        return bonusValue ? bonusValue.value : 18.75; // Valor padrão para assentos acima de 7500
    }
  
    get price_per_seat() {
        return this.useNIHSSOrganizationsBonus(this.number_of_seats);
    }
}
